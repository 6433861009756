import React from 'react';
import styled from '@emotion/styled';
import { SectionWrapper as SectionWrapperStyles, theme } from '@styles';
import { HomeTrustedBySectionData } from './types';

const { breakpoints } = theme;

const TrustedBySection: React.FC<HomeTrustedBySectionData> = (props) => {
  const { trusted_by_image_mobile, trusted_by_image, trusted_title } = props;

  return (
    <SectionWrapper id='trustedBy'>
      <Title
        dangerouslySetInnerHTML={{
          __html: trusted_title.html,
        }}
      />
      <TrustedByImageMobile src={trusted_by_image_mobile.url} alt={trusted_by_image_mobile.alt} />
      <TrustedByImage src={trusted_by_image.url} alt={trusted_by_image.alt} />
    </SectionWrapper>
  );
};

export default TrustedBySection;

const TrustedByImageMobile = styled.img`
  display: initial;
  @media screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }
`;

const TrustedByImage = styled.img`
  max-width: 941px;
  margin: 44px auto 0px auto;
  display: none;
  @media screen and (min-width: ${breakpoints.desktop}) {
    display: initial;
  }
`;

const Title = styled.div`
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  font-weight: 500;
  font-family: Poppins;
  letter-spacing: -0.1px;
  em {
    font-weight: 700;
    font-style: normal;
  }
  margin: 0 24px 40px 24px;
  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: 0 auto;
  }
`;

const SectionWrapper = styled(SectionWrapperStyles)`
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
  }
  bottom: 60px;
  position: relative;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    bottom: 0px;
  }
`;
